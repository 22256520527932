import { render, staticRenderFns } from "./smkp.vue?vue&type=template&id=20d5d24a&scoped=true&"
import script from "./smkp.vue?vue&type=script&lang=js&"
export * from "./smkp.vue?vue&type=script&lang=js&"
import style0 from "./smkp.vue?vue&type=style&index=0&id=20d5d24a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20d5d24a",
  null
  
)

export default component.exports