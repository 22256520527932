<template>
  <div class="about">
    <div class="zxjj">
      <div class="zxjj_left">
        <div class="zxjj_left_title">
          <b>中心简介</b>
        </div>
        <div  v-for="(item,index) in titleList" :key="index" :class=" index == activeIndex?'zxjj_left_listacitve':'zxjj_left_list'" @click="titleActive(item,index)">
          <a>{{item.name}}</a>
        </div>
      </div>
      <div class="zxjj_right">
        <div class="zxjj_right_top">
          <div>
            <a style="
                      display:block;
                      height: 67px;
                      line-height: 67px;
                      border-bottom: 3px solid #003068;
                      font-weight: normal;
                      color:#003068;
                      font-size:18px
                      ">
              {{acitveItem}}  
            </a>
            
          </div>
          <div>
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item>{{acitveItem}}</el-breadcrumb-item>
               
            </el-breadcrumb>
          </div>
        </div>
        <div class="zxjj_right_content">
              <p>
                系统医学研究中心（Research Center of System Medicine）由来自基础医学院生物物理学、解剖学与组织胚胎学、药理学、干细胞与再生医学、细胞生物学、生理学、病原生物学与微生物学的学科力量组建而成，现有教授/研究员23位、副教授7位、讲师1位，包括国家杰出青年科学基金获得者1名、科技部重大研究计划首席青年科学家1名、国家优秀青年基金获得者3名、教育部新世纪优秀人才获得者1名。2020年来，中心已发表3篇Science、1篇Nature、 1篇Nature Nanotechnology、3篇Cell Research、1篇Nano Letters、2篇Nature Communications、1篇Biomaterials、1篇The Journal of experimental medicine、1篇Advanced Science、 1篇Elife等20余篇研究论文。
                生物物理学是一门多技术交叉、多尺度综合观测的学科，应用物理学原理和技术、数理化学思维方法研究复杂生命现象。学科系以结构生物学和药物设计项目为重点，以浙江大学冷冻电镜中心和医学院蛋白质中心为平台依托，加大对课程建设、教材编写和教学骨干师资等的培育力度。该系集中优势力量，以重要药物靶标蛋白质的结构功能研究和基于结构的药物设计与筛选为重点，力争科研项目和科研成果取得突破。 
                药理学系建立了具有特色的教学和科研体系，承担本科、研究生（硕士、博士）、留学生（本科、研究生）三个层次课程的教学任务。该系主要有呼吸和抗炎免疫药理学、神经药理学、分子药理学和肿瘤药理学四大研究方向。2002年被评为浙江省重点学科，2007年成为卫生部重点实验室组成单位，2011年成为教育部生殖遗传重点实验室组成单位。
                解剖与组织胚胎学系现有人体解剖学、组织胚胎学、医学分子细胞生物学三个课程组，承担《系统解剖学》、《局部解剖学》、《Human Anatomy》、《组织学与胚胎学》、《Histology and Embryology》、《医学分子细胞生物学》等课程，教学面覆盖基础医学、临床医学、口腔医学、药学、护理和预防医学等专业的五年制（临床医学留学生）、七年制、八年制医学生的专业课教学，被教育部列为全国留学生解剖学教学定点培训单位，《组织胚胎学》和《人体解剖学》为浙江省精品课程。该学科系获全国科技大会一等奖1项、国家教委科技进步一等奖1项、中国高校自然科学一等奖1项，在淋巴学、肿瘤的基因组学与表观遗传学、疾病的蛋白质组学、细胞运动和细胞周期的分子调控、胃癌发病的分子机理、干细胞生物学、组织工程、临床再生医学转化、学习和记忆的分子机制、脊髓损伤和修复机制、神经系统发育机制等领域有突出的科研业绩。
              此外，中心在干细胞与再生医学、细胞生物学、生理学、病原生物学与微生物学等学科均有专职研究人员，科学技术从微观到宏观各个尺度向纵深演进，学科多点突破、交叉融合趋势日益明显。中心将紧跟各学科发展最前沿，瞄准学科发展趋势，积极推进各相关学科的交叉融合。
              </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import eventbus from '@/api/eventbus'
export default {
  data(){
    return {
      activeIndex:0,
      acitveItem:'中心简介',
      titleList:[
        {
          name:'中心简介'
        },
        {
          name:'联系我们'
        }
      ]
    }
  },
  created(){
     eventbus.$on('headerTitle',(message)=>{
       console.log(message)
            })
  },
  methods:{
    titleActive(item,index){
      this.acitveItem = item.name
      this.activeIndex = index
    }
  }
  
}
</script>
<style scoped>
.about {
  width: 100%;
} 
.zxjj {
  width: 100%;
  max-width: 1200px;
  margin: 0px auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px 0px 100px 0px;
} 
.zxjj_left {

}
.zxjj_left:hover {
  cursor: pointer;
}
.zxjj_left_title {
  width: 270px;
  height: 70px;
  background-color: #1F518C;
  text-align: center;
  line-height: 70px;
  color: #ffff;
  font-size: 18px;
}
.zxjj_left_list {
  width: 270px;
  height: 50px;
  background-color: #EBEBEB;
  border-top: 1px solid #ffff;
}
.zxjj_left_list:hover {
  background-color: #626D84;
  color:#ffff;
 
}
.zxjj_left_list  a {
  display: block;
  width: 80%;
  height: 100%;
  float: right;
  line-height: 50px;
}
.zxjj_left_listacitve {
   width: 270px;
  height: 50px;
  background-color: #626D84;
  color: #fff;
}
 .zxjj_left_listacitve a {
     display: block;
  width: 80%;
  height: 100%;
  float: right;
  line-height: 50px;
 }
 .zxjj_right {
   width: 900px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    padding: 0px 0px 0px 20px;
 }
 .zxjj_right_top {
   width: 100%;
   display: flex;
   justify-content: space-between;
   align-items: center;
   border-bottom: 1px solid #E0E0E0;
 }
 .zxjj_right_content {
   width: 100%;
   
 } 
 .zxjj_right_content p {
   text-indent: 2em;
   line-height: 20px;
       text-align: justify;
    text-justify: inter-ideograph;
    color: #333333;
    line-height: 2em;
 }
</style>
